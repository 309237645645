import './Header.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LuLogOut } from 'react-icons/lu';

import { ReactComponent as ProfileIcon } from './icons/profile_icon.svg';
import { ReactComponent as HomeIcon } from './icons/home_icon.svg';

import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import LogoutModal from './LogoutModal';

function Header() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

    Modal.setAppElement('#root');

    useEffect(() => {
        const jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            navigate("/login");
        }
    }, [navigate]);

    const handleLogout = () => {
        setLogoutModalOpen(false);
        localStorage.removeItem('jwtToken');
        navigate('/login');
    };

    const handleOpenLogoutModal = () => {
        setLogoutModalOpen(true);
    };

    const handleCloseLogoutModal = () => {
        setLogoutModalOpen(false);
    };

    const location = useLocation();

    const isActive = (routePattern) => {
        return location.pathname.startsWith(routePattern);
    };

    const homePattern = '/home';

    return (
        <>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
            <header>
                <div className="logo-container">
                    <img className="logo" src={require('../../img/WoT.png')} alt="Logo" />
                </div>
                {/* <nav className='header_navigation_left'>
                    <ul>
                        {isLoggedIn && (
                            <>
                                <li>
                                    <a href="/home" className="navlink">
                                        <div className="icon-container">
                                            <HomeIcon className={isActive(homePattern) ? 'home_icon activo' : 'home_icon'} />
                                            <span className={isActive(homePattern) ? 'navlink-text activo' : 'navlink-text'}>Inicio</span>
                                        </div>
                                    </a>
                                </li>
                            </>
                        )}
                    </ul>
                </nav> */}
                <nav className='header_navigation'>
                    <ul>
                        {isLoggedIn && (
                            <> 
                                <li>
                                    <a href="/profile" className="navlink">
                                        <div className="icon-container">
                                            <ProfileIcon className={isActive(homePattern) ? 'home_icon activo' : 'home_icon'} />
                                            <span className={isActive(homePattern) ? 'navlink-text activo' : 'navlink-text'}>Perfil</span>
                                        </div>
                                    </a>
                                </li>

                                <li>
                                    <button className="navlink" onClick={handleOpenLogoutModal}>
                                        <div className="icon-container">
                                            <LuLogOut className='navlink_icon' size={26} />
                                            <span className='navlink-text'>Cerrar Sesión</span>
                                        </div>
                                    </button>
                                    <LogoutModal isOpen={isLogoutModalOpen} onClose={handleCloseLogoutModal} onLogout={handleLogout} />
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
            </header>
        </>
    );
}

export default Header;

  