import React, { useState, useEffect } from 'react';
import Question from './Question';
import axios from 'axios';
import Cookies from 'js-cookie';
import "./Form.css";
import { validate, clean } from 'rut.js';
import phoneCode from './PhoneCode';
import { adapterForm } from '../../Adapter';
import cityImage from '../../img/Santiago.png';
import Header from '../Header/Header';
import { NextButton } from './NextButton';
import { BackButton } from './BackButton';
import { type } from '@testing-library/user-event/dist/type';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

//Funciones de validación
function validarRut(rut) {
    if (!rut || typeof rut !== 'string' || rut.trim() === '') {
        return false;
    }
    const rutLimpio = clean(rut);
    return validate(rutLimpio);
}

// Función para enviar los datos al backend
const sendDataToBackend = async (token, datos) => {
    try {
        if (datos.profesion[0] !== datos.profesion1) {
            if (!datos.profesion[0]) {
                datos.profesion[0] = {};  // Creamos un nuevo objeto si no existe
            }
            //cambiar el datos.profesion[0] por datos.profesion1
            datos.profesion[0].id = datos.profesion1;
        }
        //datos profesion 2
        if (datos.profesion[1] !== datos.profesion2 || !datos.profesion[1]) {
            if (!datos.profesion[1]) {
                datos.profesion[1] = {};  // Creamos un nuevo objeto si no existe
            }
            datos.profesion[1].id = datos.profesion2;
        }
        if (datos.experienciaDirectorios === 'true') {
            datos.experienciaDirectorios = true
        }
        if (datos.experienciaDirectorios === 'false') {
            datos.experienciaDirectorios = false
        }
        if (datos.altaDireccion === 'true') {
            datos.altaDireccion = true
        }
        if (datos.altaDireccion === 'false') {
            datos.altaDireccion = false
        }
        if (datos.disposicion_viajar === 'true') {
            datos.disposicion_viajar = true
        }
        if (datos.disposicion_viajar === 'false') {
            datos.disposicion_viajar = false
        }
        // Transforma los nombres de los campos según lo que espera el backend
        const dataToSend = adapterForm(datos); // el adapterForm hace que los datos esten en el formato del back
        // Realiza la solicitud PATCH
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/edit_profile`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error(`Error en la solicitud PATCH: ${errorMessage}`);
        } else {
            // Verificar si la respuesta tiene contenido antes de parsear
            const responseText = await response.text();
            if (responseText) {
                const jsonResponse = JSON.parse(responseText);
                console.log('Datos enviados exitosamente');
            } else {
                console.log('Datos enviados exitosamente, sin respuesta JSON');
            }
        }
    } catch (error) {
        console.error('Error en la solicitud PATCH:', error);
    }
};

export async function update_cv(token, formData){
    try{
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/users/updatecv`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}


//Componente Formulario
const Form = () => {
    const [datos, setDatos] = useState({});
    const datosPaises = JSON.parse(localStorage.getItem('datosPaises'));
    const datosRegion = JSON.parse(localStorage.getItem('datosRegion'));
    const [currentStep, setCurrentStep] = useState(1);
    const [rutValido, setRutValido] = useState(true);
    const [celValido, setCelValido] = useState(true);
    const [mostrarRegion, setMostrarRegion] = useState(false);
    const [mostrarOtraSubarea, setMostrarOtraSubarea] = useState(false);
    const [codigoTelefonico, setCodigoTelefonico] = useState('+56');
    const datosProfesiones = JSON.parse(localStorage.getItem('datosProfesiones'));
    const datosUniversidades = JSON.parse(localStorage.getItem('datosUniversidades'));
    const datosCargos = JSON.parse(localStorage.getItem('datosCargos'));
    const datosIndustrias = JSON.parse(localStorage.getItem('datosIndustrias'));
    const aniosExperiencia = JSON.parse(localStorage.getItem('aniosExperiencia'));
    const competencias = JSON.parse(localStorage.getItem('competencias'));
    const areasExperiencia = JSON.parse(localStorage.getItem('areasExperiencia'));
    const disponibilidades = JSON.parse(localStorage.getItem('disponibilidades'));
    const jornadas = JSON.parse(localStorage.getItem('jornadas'));
    const modalidades = JSON.parse(localStorage.getItem('modalidades'));
    const idiomas = JSON.parse(localStorage.getItem('idiomas'));
    const conocio = JSON.parse(localStorage.getItem('conocio'));
    const personalidades = JSON.parse(localStorage.getItem('personalidades'));
    const [selectedPostgrado, setSelectedPostgrado] = useState('');
    const [selectedPueblo, setSelectedPueblo] = useState('');
    const [selectedFactor, setSelectedFactor] = useState('');
    const [selectedCompromiso, setSelectedCompromiso] = useState('');
    const [selectedIndustrias, setSelectedIndustrias] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [selectedCompetencias, setSelectedCompetencias] = useState([]);
    const [selectedIdiomas, setSelectedIdiomas] = useState([]);
    const [selectedDisponibilidad, setSelectedDisponibilidad] = useState([]);
    const [selectedSubareas, setSelectedSubareas] = useState([]); 
    const [file, setFile] = useState(null);
    const [contacto1, setContacto1] = useState('');
    const [contacto2, setContacto2] = useState('');
    const [contactos, setContactos] = useState([]);
    const [universidad, setUniversidad] = useState(0);
    const [rolAdicional, setRolAdicional] = useState("");
    const [Subareas, setSubareas] = useState([])

    const navigate = useNavigate();



    // maneja inputs de texto 
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos(prevState => ({ ...prevState, [name]: value }));

        if (name === "rut") {
            const esRutValido = validarRut(value);
            setRutValido(esRutValido);
        }

        if (name === "celular") {
            const esCelValido = /^9\d{8}$/.test(value);
            setCelValido(datos.id_pais_domicilio === '1' ? esCelValido : true);
        }
    };

    const findUniversidad = (id) => {
        const universidadNombre = datosUniversidades.find(universidad => universidad.id === parseInt(id, 10));
        return universidadNombre ? universidadNombre.nombre : '';
    };

    const findUniversidadId = (nombre) => {

        const universidadId = datosUniversidades.find(universidad => universidad.nombre === nombre);
        return universidadId ? universidadId.id : '';
    };
    // inputs de select 
    const handleDropdownChange = (event) => {
        const { name, value } = event.target;
        setDatos(prevState => ({ ...prevState, [name]: value }));
        const newValue = value === 'true' ? true : value === 'false' ? false : value;

        if (name === 'selectedPostgrado') {  // Asegúrate de que el nombre coincide
            setSelectedPostgrado(value);
        }
        if (name === 'pueblo') {
            setSelectedPueblo(value);
        }
        if (name === 'selectedFactor') {
            setSelectedFactor(value);
        }
        if (name === 'selectedCompromiso') {    
            setSelectedCompromiso(value);
        }
        if (name === 'universidad') {
            setUniversidad(value);
            setDatos(prevState => ({ ...prevState, universidad: findUniversidad(value) }));
        }

        if (name === 'id_pais_domicilio') {
            const paisSeleccionado = datosPaises.find(pais => pais.id === parseInt(value, 10));
            if (paisSeleccionado) {
                const codigoAsociado = phoneCode.find(codigo => codigo.nombre === paisSeleccionado.pais);
                setCodigoTelefonico(codigoAsociado ? codigoAsociado.codigo : '');
            }
            setMostrarRegion(value === '1');
        }
    };

    //funcion para ir a la siguiente pagina
    const handleNext = async () => {
        const token = localStorage.getItem('jwtToken');
        datos.contactos = [contacto1, contacto2];
        sendDataToBackend(token, datos); 
        setCurrentStep(prevStep => prevStep + 1);
    };
    
    const handlePrev = () => {
        const token = localStorage.getItem('jwtToken');
        datos.contactos = [contacto1, contacto2];
        sendDataToBackend(token, datos); 
        setCurrentStep(prevStep => prevStep - 1);
    };

    //enviar formulario
    const handleSubmit = async () => {
        datos.contactos = [contacto1, contacto2];
        const token = localStorage.getItem('jwtToken');
        await sendDataToBackend(token, datos);  // Envía los datos al backend 
        console.log('Formulario enviado.');
        navigate('/profile');
    };
    
    // manejo del cv 
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
    const handleFile = async (event) => {
        alert('Se está subiendo el archivo, espere unos segundos antes de continuar');
        event.preventDefault();
        var formData = new FormData();
        formData.append('pdf', file);
        for (var key of formData.entries()) {
            }
        const jwtToken = localStorage.getItem('jwtToken')
        const response =  await update_cv(jwtToken, formData);
        if (response.status === 200){
            window.alert('Se ha subido el archivo correctamente');
        }
        else{
            window.alert('Ha ocurrido un error');
        }
    };

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };
    
    //manejo de los multi-selects
    const handleSelectionChange = (selectedOptions, name) => {
        if (selectedOptions.length > 5) {
            alert('No puedes seleccionar más de 5 opciones');
            return
        }
        if (name === 'industriasExperiencia') {
            setSelectedIndustrias(selectedOptions);
            const industriasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, industrias: industriasSeleccionadas }));
        }
        if (name === 'competencias') {
            setSelectedCompetencias(selectedOptions);
            const competenciasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, competencia: competenciasSeleccionadas }));
        }
        if (name === 'areas') {
            setSelectedAreas(selectedOptions);
            const areasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, areas: areasSeleccionadas }));

        }
        if (name === 'subareas') {
            setSelectedSubareas(selectedOptions);
            const subareasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, subareas: subareasSeleccionadas }));
            // Si seleccionan la opción "Otra" (id: 0), habilitamos un campo de texto adicional
            const isOtraSelected = selectedOptions.some(option => option.value === 0);
            setMostrarOtraSubarea(isOtraSelected); // Muestra el campo para escribir "Otra área"
        }

        if (name === 'idiomas') {
            setSelectedIdiomas(selectedOptions);
            const idiomasSeleccionados = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, idiomas: idiomasSeleccionados }));
        }
        if (name === 'disponibilidad') {
            setSelectedDisponibilidad(selectedOptions);
            const disponibilidadSeleccionada = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, disponibilidad: disponibilidadSeleccionada }));
        }

    };


    //manejo de los contactos de referencia
    const handleContactos = (event) => {
        const { name, value } = event.target;
        
        setDatos(prevState => ({
            ...prevState,
            [name]: value,
        }));
        
        if (name === "contacto_nombre1" && contacto1[0] !== value) {
            let copiaContacto = [...contacto1];
            copiaContacto[0] = value;
            setContacto1(copiaContacto);
        } else if (name === "contacto_celular1" && contacto1[2] !== value) {
            let copiaContacto = [...contacto1];
            copiaContacto[2] = value;
            setContacto1(copiaContacto);
        } else if (name === "contacto_mail1" && contacto1[1] !== value) {
            let copiaContacto = [...contacto1];
            copiaContacto[1] = value;
            setContacto1(copiaContacto);
        } else if (name === "contacto_nombre2" && contacto2[0] !== value) {
            let copiaContacto = [...contacto2];
            copiaContacto[0] = value;
            setContacto2(copiaContacto);
        } else if (name === "contacto_celular2" && contacto2[2] !== value) {
            let copiaContacto = [...contacto2];
            copiaContacto[2] = value;
            setContacto2(copiaContacto);
        } else if (name === "contacto_mail2" && contacto2[1] !== value) {
            let copiaContacto = [...contacto2];
            copiaContacto[1] = value;
            setContacto2(copiaContacto);
        }
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const responseProfile = await axios.get(
                    `${process.env.REACT_APP_API_URL}/users/get_profile`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                const profileData = responseProfile.data;
    
                // Set data only if it's not empty or null to avoid overwriting
                setDatos(profileData);
    
                if (profileData.contactos_verificacion && profileData.contactos_verificacion.length > 0) {
                    // Check for first contact (contacto1)
                    if (profileData.contactos_verificacion[0]) {
                        const contacto1Data = profileData.contactos_verificacion[0];
                        setContacto1(contacto1 => [
                            contacto1Data.nombre || contacto1[0],
                            contacto1Data.email || contacto1[1],
                            contacto1Data.telefono || contacto1[2]
                        ]);
                        setDatos(prevState => ({
                            ...prevState,
                            contacto_nombre1: contacto1Data.nombre || prevState.contacto_nombre1,
                            contacto_mail1: contacto1Data.email || prevState.contacto_mail1,
                            contacto_celular1: contacto1Data.telefono || prevState.contacto_celular1,
                        }));
                    }
    
                    // Check for second contact (contacto2)
                    if (profileData.contactos_verificacion[1]) {
                        const contacto2Data = profileData.contactos_verificacion[1];
                        setContacto2(contacto2 => [
                            contacto2Data.nombre || contacto2[0],
                            contacto2Data.email || contacto2[1],
                            contacto2Data.telefono || contacto2[2]
                        ]);
                        setDatos(prevState => ({
                            ...prevState,
                            contacto_nombre2: contacto2Data.nombre || prevState.contacto_nombre2,
                            contacto_mail2: contacto2Data.email || prevState.contacto_mail2,
                            contacto_celular2: contacto2Data.telefono || prevState.contacto_celular2,
                        }));
                    }
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };
        fetchData();
        const fetchSubareas = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const responseSubareas = await axios.post(
                    `${process.env.REACT_APP_API_URL}/obtener_listas/subareas`,
                    { areas: selectedAreas.map(area => area.value) }, // Filtras por áreas seleccionadas
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    
                // Almacenar subáreas obtenidas en el estado
                const subareasObtenidas = responseSubareas.data;
                setSubareas([{ id: 0, nombre: 'Otra' }, ...subareasObtenidas]); // Agregar 'Otra' como primera opción
            } catch (error) {
                console.error('Error al obtener subáreas:', error);
            }
        };
    
        if (selectedAreas.length > 0) { // Solo buscar subáreas si hay áreas seleccionadas
            fetchSubareas();
        }
    }, [selectedAreas]);
    
    


    //actualizar datos que se muestran en el formulario (la primera vez)
    if (datos.paisDomicilio !== null  && datos.paisDomicilio !== undefined && !datos.id_pais_domicilio) {
        datos['id_pais_domicilio'] = datos.paisDomicilio.id;
    }
    if (datos.regionActualDomicilio !== null && datos.regionActualDomicilio !== undefined && !datos.region_domicilio) {
        datos['region_domicilio'] = datos.regionActualDomicilio.id;
    }
    if (datos.profesion && datos.profesion.length > 0 && !datos.profesion1 ) {
        if (datos.profesion[0] && datos.profesion[0].id) {
            datos['profesion1'] = datos.profesion[0].id;
        }
    }
    if (datos.profesion && datos.profesion.length > 1 && !datos.profesion2 ) {
        if (datos.profesion[1] && datos.profesion[1].id) {
            datos['profesion2'] = datos.profesion[1].id;
        }
    }    
    if (datos.postgrado !== null && datos.postgrado !== undefined && !selectedPostgrado && datos.postgrado !== '') {
        setSelectedPostgrado(datos.postgrado ? '1' : '0');
    }
    if (datos.cargo && datos.cargo.id && !datos.id_cargo) {
        datos['id_cargo'] = datos.cargo.id;
    }
    if (datos.aditionalCargo && datos.aditionalCargo.id && !datos.id_cargo_adicional) {
        datos['id_cargo_adicional'] = datos.aditionalCargo.id;
    }
    if (datos.industria && datos.industria.id && !datos.id_industria_actual) {
        datos['id_industria_actual'] = datos.industria.id;
    }
    if (datos.aditionalIndustria && datos.aditionalIndustria.id && !datos.id_industria_adicional) {
        datos['id_industria_adicional'] = datos.aditionalIndustria.id;
    }
    if(datos.aditionalCargo && datos.aditionalCargo.id && rolAdicional === ""){
        setRolAdicional("1")
    }
    if (datos.aniosExperiencia && datos.aniosExperiencia.id && !datos.id_anios_experiencia) {
        datos['id_anios_experiencia'] = datos.aniosExperiencia.id;
    }
    if (datos.tipoJornada && datos.tipoJornada.id && !datos.id_jornada) {
        datos['id_jornada'] = datos.tipoJornada.id;
    }
    if (datos.tipoModalidad && datos.tipoModalidad.id && !datos.id_modalidad) {
        datos['id_modalidad'] = datos.tipoModalidad.id;
    }
    if (datos.conocioWOT && datos.conocioWOT.id && !datos.id_conocio_wot) {
        datos['id_conocio_wot'] = datos.conocioWOT.id;
    }
    if (datos.personalidad && datos.personalidad.id && !datos.id_personalidad) {
        datos['id_personalidad'] = datos.personalidad.id;
    }

    if (datos.areas && datos.areas.length > 0 && selectedAreas.length === 0) {
        const areasSeleccionadas = datos.areas.map(area => ({
            value: area.id,
            label: area.nombre
        }));
        
        setSelectedAreas(areasSeleccionadas);
    }

    if (datos.subareas && datos.subareas.length > 0 && selectedSubareas.length === 0) {
        const areasSeleccionadas = datos.subareas.map(subarea => ({
            value: subarea.id,
            label: subarea.nombre
        }));
        console.log(datos.otrasSubareas)
        if (datos.otrasSubareas.length > 0 && datos.otrasSubareas) {
            areasSeleccionadas.push({
                value: 0,
                label: "Otra"
            });
            setMostrarOtraSubarea(true);
        }

        if (datos.otrasSubareas){
           if( datos.otrasSubareas.length > 0) {
            datos.otrasSubareas = datos.otrasSubareas[0]
            console.log(datos.otrasSubareas)

           }
           else {
            datos.otrasSubareas = ''
            console.log(datos.otrasSubareas)
           }
        }
        
        setSelectedSubareas(areasSeleccionadas);

        
    }
    if (datos.competencia && datos.competencia.length > 0 && selectedCompetencias.length === 0) {
        const competenciasSeleccionadas = datos.competencia.map(competencia => ({
            value: competencia.id,
            label: competencia.competencia
        }));
        setSelectedCompetencias(competenciasSeleccionadas);
    }
    if (datos.idiomas && datos.idiomas.length > 0 && selectedIdiomas.length === 0) {
        const idiomasSeleccionados = datos.idiomas.map(idioma => ({
            value: idioma.id,
            label: idioma.nombre
        }));
        setSelectedIdiomas(idiomasSeleccionados);
    }
    if (datos.industrias && datos.industrias.length > 0 && selectedIndustrias.length === 0) {
        const industriasSeleccionadas = datos.industrias.map(industria => ({
            value: industria.id,
            label: industria.nombre_industria
        }));
        setSelectedIndustrias(industriasSeleccionadas);
    }
    if (datos.disponibilidad && datos.disponibilidad.length > 0 && selectedDisponibilidad.length === 0) {   
        const disponibilidadSeleccionada = datos.disponibilidad.map(disponibilidad => ({
            value: disponibilidad.id,
            label: disponibilidad.disponibilidad
        }));
        setSelectedDisponibilidad(disponibilidadSeleccionada);
    }
    if ( datos.aditionalCrgo || datos.aditionalIndustria || datos.empresa_adicional) {
        datos['rolAdicional'] = '1';
    }
    if (datos.posibilidadCambiarseRegion && datos.posibilidadCambiarseRegion.id &&  !datos.id_posibilidad_cambiarse_region) {
        datos['id_posibilidad_cambiarse_region'] = datos.posibilidadCambiarseRegion.id;
    }
    if (datos.regionCompromiso && datos.regionCompromiso.id && !datos.id_region_con_compromiso) {
        datos['id_region_con_compromiso'] = datos.regionCompromiso.id;
    }
    if (datos.id_region_con_compromiso && datos.id_region_con_compromiso !== null && !selectedCompromiso) {
        setSelectedCompromiso('Si');
    }
    if (datos.factor !== null && datos.factor !== undefined && !selectedFactor) {
        setSelectedFactor('Si');
    }
    if (datos.nombrePuebloOriginario !== null &&  datos.nombrePuebloOriginario !==  undefined  && !selectedPueblo) {
        setSelectedPueblo('Si');
    }
    if (datos.contactos_verificacion && datos.contactos_verificacion.length > 0 && contactos.length === 0) {
        if (datos.contactos_verificacion[0].nombre ) {
            datos['contacto_nombre1'] = datos.contactos_verificacion[0].nombre;
            datos['contacto_mail1'] = datos.contactos_verificacion[0].email;
            datos['contacto_celular1'] = datos.contactos_verificacion[0].telefono;
        }
        if (datos.contactos_verificacion[1]) {
            if (datos.contactos_verificacion[1].nombre) {
            datos['contacto_nombre2'] = datos.contactos_verificacion[1].nombre;
            datos['contacto_mail2'] = datos.contactos_verificacion[1].email;
            datos['contacto_celular2'] = datos.contactos_verificacion[1].telefono;
            }
        }
    }
    if (datos.universidad  && currentStep === 2 && universidad === 0) {
        const idUniversidad = findUniversidadId(datos.universidad);
        setUniversidad(idUniversidad);
    }

    //MANEJA TODAS LAS PREGUNTAS DEL FORMULARIO
    const questions = [
        {
            step: 1,
            label: "Nombre",
            description: "Ej: Josefina",
            name: "nombre",
            value: datos.nombre || '',
            onChange: handleInputChange,
            isFieldEmpty: !datos.nombre,
            
        },
        {
            step: 1,
            label: "Apellido",
            description: "Ej: Lagos",
            name: "apellido",
            value: datos.apellido || '',
            isFieldEmpty: !datos.apellido,
            onChange: handleInputChange,
        },
        {
            step: 1,
            label: "Rut",
            description: "Ej: 23245245-K",
            name: "rut",
            value: datos.rut || '',
            isFieldEmpty: !datos.rut,
            isValid: rutValido,
            invalidMessage: "RUT inválido",
            onChange: handleInputChange,
        },
        {
            step: 1,
            label: "País de domicilio",
            type: "select",
            name: "id_pais_domicilio",
            value: datos.id_pais_domicilio || '',
            options: datosPaises.map((country) => ({
                value: country.id,
                label: country.pais
            })),
            isFieldEmpty: !datos.id_pais_domicilio,
            onChange: handleDropdownChange,
        },
        {
            step: 1,
            label: "Intereses",
            description: "Señala tus principales intereses personales o profesionales. Máximo 3 palabras separadas por coma.",
            name: "intereses",
            value: datos.intereses || '',
            isFieldEmpty: !datos.intereses,
            onChange: handleInputChange,
        },
        {
            step: 1,
            label: "Celular",
            description: "Ej: 962806381",
            name: "celular",
            codigo: codigoTelefonico,
            type: "tel",
            value: datos.celular || '',
            isFieldEmpty: !datos.celular,
            isValid: celValido,
            invalidMessage: "Celular inválido",
        },
        {
            step: 1,  // Misma página que la selección de país
            label: "Región",
            type: "select",
            name: "region_domicilio",
            value: datos.region_domicilio || '',
            options: datosRegion.map((region) => ({
                value: region.id,
                label: region.nombre
            })),
            isFieldEmpty: !datos.region_domicilio,
            onChange: handleDropdownChange,
            isVisible: datos.id_pais_domicilio === 1,  // Propiedad adicional para controlar la visibilidad
        },
        {
            step: 2,
            label: "Profesión",
            description: "Elige la opción que más se acomode a tu profesión",
            type: "select",
            name: "profesion1",
            value: datos.profesion1 || '',
            options: datosProfesiones.map((profesion) => ({
                value: profesion.id,
                label: profesion.nombre
            })),
            isFieldEmpty: !datos.profesion1,
            onChange: handleDropdownChange,
        },
        {
            step: 2,
            label: "Segunda Profesión",
            description: "Selecciona otra profesión si es que tienes", 
            type: "select",
            name: "profesion2",
            value: datos.profesion2 || '',
            options: datosProfesiones.map((profesion) => ({
                value: profesion.id,
                label: profesion.nombre
            })),
            isFieldEmpty: !datos.profesion2,
            onChange: handleDropdownChange,
        },
        {
            step: 2,
            label: "Universidad",
            description: "Selecciona tu universidad de pregrado",
            type: "select",
            name: "universidad",
            value: universidad || '',
            options: datosUniversidades.map((universidad) => ({
                value: universidad.id,
                label: universidad.nombre
            })),
            isFieldEmpty: universidad,
            onChange: handleDropdownChange,
        }, 
        {
            step: 2,
            label: "Postgrado",
            description: "Se considera como tal al que acredita la obtención de un grado académico equivalente a magíster o doctorado, otorgado por una universidad reconocida por el Estado, nacional o extranjera.",
            type: "select",
            name: "selectedPostgrado",
            value: selectedPostgrado ,
            options: [
                { value: '1', label: 'Sí' },
                { value: '0', label: 'No' },
            ],
            isFieldEmpty: !selectedPostgrado,
            onChange: handleDropdownChange,
        }, 
        {
            step: 2,
            label: "Qué postgrado tienes", 
            description: "Indicar título y universidad", 
            name: "postgrado",
            value: datos.postgrado || '',
            isFieldEmpty: !datos.postgrado,
            isVisible: selectedPostgrado === '1', //ver si se va a guardar como si o no 
        },
        {
            step:3, 
            label: "Cargo actual",
            description: "Elige la opción que más se acomode a tu cargo. Si no tienes cargo selecciona \"Ninguno\"",
            type: "select",
            name: "id_cargo",
            value: datos.id_cargo || '',
            options: datosCargos.map((cargo) => ({
                value: cargo.id,
                label: cargo.cargo
            })),
            isFieldEmpty: !datos.id_cargo,
            onChange: handleDropdownChange,
        },
        {
            step:3, 
            label: "Empresa actual",
            description: "Nombre de la empresa en la que trabajas",
            name: "empresa_actual",
            value: datos.empresa_actual || '',
            isFieldEmpty: !datos.empresa_actual,
        },
        {
            step: 3, 
            label: "Industria actual", 
            description: "Se entiende como industria el área de la economía en la que tu empresa u organización se desempeña", 
            name: "id_industria_actual",
            type: "select",
            options: datosIndustrias.map((industria) => ({
                value: industria.id,
                label: industria.nombre_industria
            })),
            value: datos.id_industria_actual || '',
            isFieldEmpty: !datos.id_industria_actual,
            onChange: handleDropdownChange,
        }, 
        {
            step: 3,
            label: "¿Desempeñas algún otro rol, cargo o funcion actualmente?", 
            name: "rolAdicional",
            value: rolAdicional | "",
            type: "select",
            options: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sí' },
            ],
        }, 
        {
            step: 3,  
            label: "Cargo adicional o complementario",
            name: "id_cargo_adicional",
            value: datos.id_cargo_adicional || '',
            type: "select",
            options: datosCargos.map((cargo) => ({
                value: cargo.id,
                label: cargo.cargo
            })),
            isFieldEmpty: !datos.id_cargo_adicional,
            isVisible: datos.rolAdicional === '1',
            onChange: handleDropdownChange,
        },
        {
            step: 3,
            label: "Empresa adicional o complementaria",
            name: "empresa_adicional",
            value: datos.empresa_adicional || '',
            isFieldEmpty: !datos.empresa_adicional,
            isVisible: datos.rolAdicional === '1',
        }, 
        {
            step: 3,
            label: "Industria adicional o complementaria",
            name: "id_industria_adicional",
            value: datos.id_industria_adicional || '',
            type: "select",
            options: datosIndustrias.map((industria) => ({
                value: industria.id,
                label: industria.nombre_industria
            })),
            isFieldEmpty: !datos.id_industria_adicional,
            isVisible: datos.rolAdicional === '1',
            onChange: handleDropdownChange,
        },
        {
            step: 4,
            label: "Años de experiencia",
            name: "id_anios_experiencia",
            value: datos.id_anios_experiencia || '',
            type: "select",
            options: aniosExperiencia.map((anios) => ({
                value: anios.id,
                label: anios.rango
            })),
        },
        {
            step: 4,
            label: "Sector o industria en la que tienes experiencia",
            description: "Seleccionar máximo 5 respuestas",
            name: "industriasExperiencia",
            type: "multi-select",
            value: selectedIndustrias || [],
            options: datosIndustrias.map((industria) => ({
                value: industria.id,
                label: industria.nombre_industria
            })),
            isFieldEmpty: !datos.industriasExperiencia,
            onChange: handleSelectionChange,
        },
        {
            step: 4,
            label: "Competencias", 
            description: "Seleccionar máximo 5 respuestas , priorizando las fortalezas y talentos que mas te representan",
            name: "competencias",
            type: "multi-select",
            value: selectedCompetencias || [],
            options: competencias.map((competencia) => ({
                value: competencia.id,
                label: competencia.competencia
            })),
            isFieldEmpty: !datos.competencias,
            onChange: handleSelectionChange,
        },
        {
            step: 4,
            label: "Experiencia en directorios",
            description: "Esto significa ejercer o haber ejercido el rol de directora",
            name: "experienciaDirectorios",
            type: "select",
            options: [
                { value: 'true', label: 'Sí' },
                { value: 'false', label: 'No' },
            ],
            value: (datos.experienciaDirectorios != null) ? datos.experienciaDirectorios.toString() : '', // Usamos != para cubrir undefined y null
            isFieldEmpty: datos.experienciaDirectorios === undefined || datos.experienciaDirectorios === null,
            onChange: handleDropdownChange,
        },
        {
            step: 5, 
            label: "Áreas de experiencia", 
            description: "Seleccionar máximo 5 respuestas. Indica las áreas en las que has trabajado anteriomente",
            name: "areas",
            type: "multi-select",
            value: selectedAreas || [],
            options: areasExperiencia.map((area) => ({
                value: area.id,
                label: area.nombre
            })),
            isFieldEmpty: !datos.areas,
            onChange: handleSelectionChange,
        },
        {
            step: 5, 
            label: "Formación en alta dirección",
            name: "altaDireccion",
            type: "select",
            options: [
                { value: 'true', label: 'Sí' },
                { value: 'false', label: 'No' },
            ],
            value:  datos.altaDireccion != null ? datos.altaDireccion.toString() : '', // Convertir el valor a cadena
            isFieldEmpty: datos.altaDireccion === undefined || datos.altaDireccion === null,
            onChange: handleDropdownChange,
        },
        {
            step: 5, 
            label: "Subáreas de experiencia", 
            description: "Seleccionar máximo 5 respuestas. Indica las áreas en las que has trabajado anteriomente",
            name: "subareas",
            type: "multi-select",
            value: selectedSubareas || [],
            options: Subareas.map((subarea) => ({
                value: subarea.id,
                label: subarea.nombre
            })),
            isFieldEmpty: !datos.subareas,
            onChange: handleSelectionChange,
        },
        {
            step: 5,
            label: "Otra subárea de experiencia",
            description: "Escribe el nombre de la otra subárea de experiencia",
            name: "otrasSubareas",
            type: "text",
            value: datos.otrasSubareas,
            isFieldEmpty: !datos.otrasSubareas,
            isVisible: mostrarOtraSubarea, // Solo se muestra si 'Otra' está seleccionada
            onChange: handleInputChange,
        },
        {
            step: 6, 
            label: "Disponibilidad", 
            description: "Seleccionar una o más respuestas Indica las modalidades en que estarías dispuesta asumir un nuevo trabajo, proyecto o asesoría",
            name: "disponibilidad",
            type: "multi-select",
            value: selectedDisponibilidad || [],
            options: disponibilidades.map((disponibilidad) => ({
                value: disponibilidad.id,
                label: disponibilidad.disponibilidad
            })),
            isFieldEmpty: !selectedDisponibilidad,
            onChange: handleSelectionChange,
        },
        {
            step: 6,
            label: "Posibilidad de cambiarse de región",
            type: "select",
            name: "id_posibilidad_cambiarse_region",
            value: datos.id_posibilidad_cambiarse_region || '',
            options: [
                { value: '1', label: 'Sí' },
                { value: '2', label: 'No' },
            ],
            isFieldEmpty: !datos.id_posibilidad_cambiarse_region,
        },
        {
            step: 6, 
            label: "Disponibilidad de jornada", 
            type: "select",
            name: "id_jornada",
            value: datos.id_jornada || '',
            options: jornadas.map((jornada) => ({  
                value: jornada.id,
                label: jornada.tipoJornada
            })),
            isFieldEmpty: !datos.id_jornada,
            onChange: handleDropdownChange,
        }, 
        {
            step: 6,
            label: "Modalidad de preferencia", 
            type: "select",
            name: "id_modalidad",
            value: datos.id_modalidad || '',
            options: modalidades.map((modalidad) => ({
                value: modalidad.id,
                label: modalidad.tipoModalidad
            })),
            isFieldEmpty: !datos.id_modalidad,
            onChange: handleDropdownChange,
        }, 
        {
            step: 6,
            label: "Disposición a viajar",
            type: "select",
            name: "disposicion_viajar",
            value:  datos.disposicion_viajar != null ? datos.disposicion_viajar.toString() : '', // Convertir el valor a cadena
            options: [
                { value: 'true', label: 'Sí' },
                { value: 'false', label: 'No' },
            ],
            isFieldEmpty: datos.disposicion_viajar === undefined || datos.disposicion_viajar === null,
            onChange: handleDropdownChange,
        },
        {
            step: 6,
            label: "Expectativa de renta líquida", 
            name: "expectatviaRenta",
            value: datos.expectatviaRenta || '',
            isFieldEmpty: !datos.expectatviaRenta,
        }, 
        {
            step: 7, 
            label: "Dominio de idioma medio-avanzado", 
            type: "multi-select",
            name: "idiomas",
            value: selectedIdiomas || [],
            options: idiomas.map((idioma) => ({
                value: idioma.id,
                label: idioma.nombre
            })),
            isFieldEmpty: !selectedIdiomas,
            onChange: handleSelectionChange,
        },
        {
            step: 7,
            label: "Cómo conocío WoT",
            name: "id_conocio_wot",
            value: datos.id_conocio_wot || '',
            type: "select",
            options: conocio.map((conocio) => ({
                value: conocio.id,
                label: conocio.conocio
            })),
            isFieldEmpty: !datos.id_conocio_wot,
            onChange: handleDropdownChange,
        },
        {
            step: 7, 
            label: "Que herramientas adicionales manejas",
            description: 'Máximo 350 caracteres',
            type: "textarea",
            name: "herramientas",
            value: datos.herramientas || '',
            isFieldEmpty: !datos.herramientas,
            handleInputChange: handleInputChange,
        },
        {
            step: 8,
            label: "Tiene algún factor de inclusión laboral", 
            type: "select",
            name: "selectedFactor",
            value: selectedFactor|| '',
            options: [
                { value: 'Si', label: 'Sí' },
                { value: 'No', label: 'No' },
            ],
            isFieldEmpty: !selectedFactor,
            onChange: handleDropdownChange,

        }, 
        {
            step: 8,
            label: "¿Cual es su factor de inclusión laboral?", 
            name: "factor",
            value: datos.factor || '',
            isFieldEmpty: !datos.factor,
            isVisible: selectedFactor=== 'Si',
        }, 
        {
            step: 8,
            label: "Tiene algún compromiso con alguna región del pais", 
            type: "select",
            name: "selectedCompromiso",
            value: selectedCompromiso || '',
            options: [
                { value: 'Si', label: 'Sí' },
                { value: 'No', label: 'No' },
            ],
            isFieldEmpty: !selectedCompromiso,
            onChange: handleDropdownChange,

        }, 
        {
            step: 8,
            label: "Selecciona la region con cual estas comprometida", 
            name: "id_region_con_compromiso",
            value: datos.id_region_con_compromiso || '',
            isFieldEmpty: !datos.id_region_con_compromiso,
            isVisible: selectedCompromiso === 'Si',
            type: "select",
            options: datosRegion.map((region) => ({
                value: region.id,
                label: region.nombre
            })),
            onChange: handleDropdownChange,
        }, 
        {
            step: 8,
            label: "Se identifica con algún pueblo originario", 
            type: "select",
            name: "pueblo",
            value: selectedPueblo || '',
            options: [
                { value: 'Si', label: 'Sí' },
                { value: 'No', label: 'No' },
            ],
            isFieldEmpty: !selectedPueblo,
            onChange: handleDropdownChange,

        }, 
        {
            step: 8,
            label: "Con que pueblo originario se identifica?", 
            name: "nombrePuebloOriginario",
            value: datos.nombrePuebloOriginario || '',
            isFieldEmpty: !datos.nombrePuebloOriginario,
            isVisible: selectedPueblo === 'Si',
        }, 
        {
            step: 9,
            label: "Perfil de linkedin", 
            description: "Puede ser el perfil de RRSS que quieras compartir con nosotros.",
            name: "redesSociales",
            value: datos.redesSociales || '',
            isFieldEmpty: !datos.redesSociales,
            onChange: handleInputChange,
        },
        {
            step: 9,
            label: "Respuesta formulario de personalidad", 
            description: "https://www.humanmetrics.com/personalidad/test", 
            type: "select",
            name: "id_personalidad",
            value: datos.id_personalidad || '',
            options: personalidades.map((personalidad) => ({
                value: personalidad.id,
                label: personalidad.personalidad
            })),
            isFieldEmpty: !datos.id_personalidad,
            onChange: handleDropdownChange,

        },
        {
            step: 9, 
            label: "Realiza un pequeño brief sobre ti",
            description: 'Máximo 350 caracteres',
            placeholder: '[carrera] de la Universidad [nombre de la universidad], con más de [años] de experiencia en áreas [indicar áreas de experiencia].Tengo la capacidad de [indicar capacidades y habilidades] y los siguientes logros en mi experiencia laboral [indicar logros].Además, me interesa desarrollar [indicar que le interesa desarrollar] y otros intereses como [indicar otros intereses].',
            type: "textarea",
            name: "brief",
            value: datos.brief || '',
            isFieldEmpty: !datos.brief,
            handleInputChange: handleInputChange,
        },
        {
            step: 9,
            name: "pdfFile",
            type: "file",
            value: datos.cv || '',
            isFieldEmpty: !datos.cv, 
            handleFile: handleFile,
            onChange: handleFileChange
        }, 
        {
            step: 10, 
            label: "Tiene algún contacto para validar la información",
            type: "select",
            name: "contacto_validacion1",
            value: datos.contacto_validacion1,
            options: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sí' },
                
            ],
            onChange: handleDropdownChange,
        }, 
        {
            step: 10,
            label: "Nombre contacto de referencia",
            name: "contacto_nombre1",
            value: datos.contacto_nombre1 || '',
            isFieldEmpty: !datos.contacto_nombre1,
            isVisible: datos.contacto_validacion1 === '1',
            onChange: handleContactos,
        }, 
        {
            step: 10,
            label: "Celular contacto de referencia",
            name: "contacto_celular1",
            value: datos.contacto_celular1 || '',
            isFieldEmpty: !datos.contacto_celular1,
            isVisible: datos.contacto_validacion1 === '1',
            onChange: handleContactos,
        },
        {
            step: 10,
            label: "Email contacto de referencia",
            name: "contacto_mail1",
            value: datos.contacto_mail1 || '',
            isFieldEmpty: !datos.contacto_mail1,
            isVisible: datos.contacto_validacion1 === '1',
            onChange: handleContactos,
        }, 
        {
            step: 11, 
            label: "Tiene otro contacto para validar la información",
            type: "select",
            name: "contacto_validacion2",
            value: datos.contacto_validacion2 || '',
            options: [
                { value: '0', label: 'No' },
                { value: '1', label: 'Sí' },
                
            ],
            isFieldEmpty: !datos.contacto_validacion2,
        }, 
        {
            step: 11,
            label: "Nombre contacto de referencia",
            name: "contacto_nombre2",
            value: datos.contacto_nombre2 || '',
            isFieldEmpty: !datos.contacto_nombre2,
            isVisible: datos.contacto_validacion2 === '1',
            onChange: handleContactos,
        },
        {
            step: 11,
            label: "Celular contacto de referencia",
            name: "contacto_celular2",
            value: datos.contacto_celular2 || '',
            isFieldEmpty: !datos.contacto_celular2,
            isVisible: datos.contacto_validacion2 === '1',
            onChange: handleContactos,
        },
        {
            step: 11,
            label: "Email contacto de referencia",
            name: "contacto_mail2",
            value: datos.contacto_mail2 || '',
            isFieldEmpty: !datos.contacto_mail2,
            isVisible: datos.contacto_validacion2 === '1',
            onChange: handleContactos,
        }

    ];
    return (
        <div>
            <Header />
            <img src={cityImage} alt="City Background" className="image-city" />
            {/* <div className='fondo_gris'></div> */}
            <div className='fondo_rosado'></div>
            
            <div className='fondo_blanco'></div>
            <div className='container-preguntas'>
                <div className="horizontal-bar-form">
                    <button className= {currentStep === 1? "button-activate-infoprofesional-form" : "button-inactivate-infoprofesional-form"} onClick={() => handleStepChange(1)} >Información personal  </button>
                    <button  className={(currentStep >= 2 && currentStep <= 5)? "button-activate-form" : "button-inactivate-form"} onClick={() => handleStepChange(2)}>Información profesional </button>
                    <button className={currentStep === 6 ? "button-activate-infoadicional-form" : "button-inactivate-infoadicional-form"} onClick={() => handleStepChange(6)}> Disponibilidad  <br className='br-mobile' /><br />  </button>
                    <button className= {(currentStep >= 7 && currentStep <= 9) ? "button-activate-form" : "button-inactivate-form"} onClick={() => handleStepChange(7)}> Información adicional </button>
                    <button className={(currentStep === 10 || currentStep === 11) ?  "button-activate-form" : "button-inactivate-form"} onClick={() => handleStepChange(10)}> Referencias  <br className='br-mobile' /><br /> </button>
                    <button className="close-button" onClick={handleSubmit}>
                    <FaTimes />
                    </button>
                </div>
                
                <div className='container_pregunta'>
                {questions
                .filter(question => question.step === currentStep)
                .map(question => (
                    <Question
                        key={question.name}
                        label={question.label}
                        description={question.description}
                        name={question.name}
                        value={question.value}
                        onChange={question.onChange || handleInputChange}
                        isFieldEmpty={question.isFieldEmpty}
                        isValid={question.isValid}
                        invalidMessage={question.invalidMessage}
                        type={question.type || 'text'}
                        options={question.options || []}
                        isVisible={question.isVisible !== undefined ? question.isVisible : true}
                        codigo={question.codigo} 
                        placeholder={question.placeholder}
                        handleFile={question.handleFile}
                    />
                ))
                }


                </div>
                <div className='button-container-form' >
            {currentStep !== 1 && (
                <BackButton handleBack={handlePrev} buttonText="Anterior" buttonColor="#5A5A5A" marginTopMobile='-8rem'/>
            )}
            {currentStep === 1 && (
                <BackButton buttonText="Anterior" buttonColor="#d3d3d3" disabled={true}  marginTopMobile='-8rem'/>
            )}
            {currentStep < questions[questions.length - 1].step && (
                <NextButton handleNext={handleNext} buttonText="Siguiente" buttonColor="#e6007e"  marginTopMobile='-17rem'/>
            )}
            {currentStep === questions[questions.length - 1].step && (
                <NextButton handleNext={handleSubmit} buttonText="Enviar" buttonColor="#e6007e"  marginTopMobile='-17rem' />
            )}
            </div>
            <div></div>
                
            
            {/* {currentStep !== 1 && (<BackButton handleBack={handlePrev} buttonText="Anterior" buttonColor="#5A5A5A"/>)}
            {currentStep === 1 && (<BackButton buttonText="Anterior" buttonColor="#d3d3d3" disabled={true}/>)}
            {currentStep < questions[questions.length -1].step && <NextButton handleNext={handleNext} buttonText="Siguiente" buttonColor="#e6007e" /> }
            {currentStep === questions[questions.length -1].step && <NextButton handleNext={handleSubmit} buttonText="Enviar" buttonColor="#e6007e" /> } */}
            </div>
            <div></div>

            {/* <div className='container-perfil'></div> */}
            
        </div>
        
    );
};

export default Form;
