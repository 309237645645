export function adapterForm(datos) {
    return {
        name: datos.nombre,
        lastName: datos.apellido,
        mail: datos.mail,
        rut: datos.rut,
        celular: datos.celular,
        universidad: datos.universidad,
        postgrado: datos.postgrado,
        id_cargo: datos.id_cargo ? datos.id_cargo : null,
        empresa_actual: datos.empresa_actual,
        id_industria_actual: datos.id_industria_actual ? datos.id_industria_actual : null,
        id_industria_adicional: datos.id_industria_adicional ? datos.id_industria_adicional : null,
        id_cargo_adicional: datos.id_cargo_adicional ? datos.id_cargo_adicional : null,
        empresa_adicional: datos.empresa_adicional,
        id_anios_experiencia: datos.id_anios_experiencia ? datos.id_anios_experiencia : null,
        experienciaDirectorios: datos.experienciaDirectorios,
        altaDireccion: datos.altaDireccion,
        intereses: datos.intereses,
        brief: datos.brief,
        redesSociales: datos.redesSociales,
        id_personalidad: datos.id_personalidad ? datos.id_personalidad : null,
        factor: datos.factor,
        nombrePuebloOriginario: datos.nombrePuebloOriginario,
        id_region_con_compromiso: datos.id_region_con_compromiso ? datos.id_region_con_compromiso : null,
        id_pais_domicilio: datos.id_pais_domicilio ? datos.id_pais_domicilio : null,
        region_domicilio: datos.region_domicilio ? datos.region_domicilio : null,
        id_posibilidad_cambiarse_region: datos.id_posibilidad_cambiarse_region ? datos.id_posibilidad_cambiarse_region : null,
        disposicion_viajar: datos.disposicion_viajar,
        id_modalidad: datos.id_modalidad ? datos.id_modalidad : null,
        id_jornada: datos.id_jornada ? datos.id_jornada : null,
        id_conocio_wot: datos.id_conocio_wot ? datos.id_conocio_wot : null,
        declaracion: datos.declaracion,
        profesiones: datos.profesion ? datos.profesion.map(p => p.id) : [],
        industriasExperiencia: datos.industrias ? datos.industrias.map(i => i.id) : [],
        disponibilidad: datos.disponibilidad ? datos.disponibilidad.map(d => d.id) : [],
        areasExperiencia: datos.areas ? datos.areas.map(a => a.id) : [],
        subareas: datos.subareas ? datos.subareas.map(a => a.id) : [],
        otraSubarea: String(datos.otrasSubareas || ""),
        competencias: datos.competencia ? datos.competencia.map(c => c.id) : [],
        idiomas: datos.idiomas ? datos.idiomas.map(i => i.id) : [],
        contactos: datos.contactos,
        expectatviaRenta: datos.expectatviaRenta,
        herramientas: datos.herramientas,
    };
}